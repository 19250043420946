"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Wonka = __importStar(require("wonka"));
var cachedOperations = new Set(["GET", "get"]);
var shouldCacheOperation = function (operation) {
    if (cachedOperations.has(operation.type) &&
        operation.networkPolicy !== "network-only") {
        return true;
    }
    return false;
};
var cacheExchange = function (_a) {
    var forward = _a.forward, client = _a.client;
    return function (operationStream) {
        var resultCache = new Map();
        var sharedOpsStream = Wonka.share(operationStream);
        var hasCache = function (operation) {
            return ((shouldCacheOperation(operation) && resultCache.has(operation.key)) ||
                !!operation.optimisticResponse);
        };
        var cachedStream = Wonka.pipe(sharedOpsStream, Wonka.filter(function (operation) { return hasCache(operation); }), Wonka.map(function (operation) {
            var cached = resultCache.get(operation.key);
            var optimisticResponse = operation.optimisticResponse;
            var merged = operation.optimisticStrategy === "cache-first"
                ? __assign(__assign({}, (optimisticResponse || {})), (cached || {})) : __assign(__assign({}, (cached || {})), (optimisticResponse || {}));
            var result = {
                operation: operation,
                stale: false,
                data: cached || optimisticResponse ? merged : undefined
            };
            if (optimisticResponse) {
                resultCache.set(operation.key, result.data);
            }
            // send revalidation command
            if (operation.networkPolicy === "network-and-cache") {
                client.nextOperation(__assign(__assign({}, operation), { networkPolicy: "network-only" }));
                result.stale = true;
            }
            result.operation.meta["cacheHit"] = true;
            result.operation.meta["optimistic"] = !!optimisticResponse;
            return result;
        }));
        var forwardStream = Wonka.pipe(sharedOpsStream, Wonka.filter(function (operation) { return !hasCache(operation); }), forward, Wonka.tap(function (result) {
            if (result.data) {
                result.operation.meta["cacheHit"] = false;
                resultCache.set(result.operation.key, result.data);
            }
        }));
        return Wonka.merge([cachedStream, forwardStream]);
    };
};
exports.default = cacheExchange;
