"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Wonka = __importStar(require("wonka"));
var exportExchange = function (_a) {
    var forward = _a.forward;
    return function (operationStream) {
        var ongoingOps = new Set();
        return Wonka.pipe(operationStream, Wonka.filter(function (op) {
            var _a;
            if (((_a = op.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== "get")
                return true;
            if (ongoingOps.has(op.key))
                return false;
            ongoingOps.add(op.key);
            return true;
        }), forward, Wonka.tap(function (result) {
            if (ongoingOps.has(result.operation.key)) {
                ongoingOps.delete(result.operation.key);
            }
        }));
    };
};
exports.default = exportExchange;
