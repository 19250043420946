"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NormalizedCache = void 0;
// for future reference if normalized cache is necessary
var NormalizedCache = /** @class */ (function () {
    function NormalizedCache() {
        this.data = {};
    }
    NormalizedCache.prototype.modify = function (modifier) {
        // this.data = produce(this.data, modifier);
    };
    NormalizedCache.prototype.identify = function (table, id) {
        return table + ":" + id;
    };
    NormalizedCache.prototype.lookup = function (ref, depth) {
        if (depth === void 0) { depth = 0; }
        var record = {};
        var _a = ref.__ref.split(":"), table = _a[0], id = _a[1];
        var cacheKey = this.identify(table, id);
        var cache = this.data[cacheKey];
        for (var _i = 0, _b = Object.entries(cache); _i < _b.length; _i++) {
            var _c = _b[_i], key = _c[0], value = _c[1];
            record[key] =
                typeof value !== "object"
                    ? value
                    : depth > 0
                        ? this.lookup(value, depth - 1)
                        : undefined;
        }
        return record;
    };
    NormalizedCache.prototype.read = function (view, id) {
        return this.lookup({ __ref: this.identify(view.tableId, id) }, 1);
    };
    NormalizedCache.prototype.write = function (view, rows) {
        var _loop_1 = function (row) {
            var id = this_1.identify(view.tableId, row.id);
            var _loop_2 = function (field) {
                if (typeof row === "object")
                    return { value: void 0 };
                this_1.modify(function (draft) {
                    draft[id][field.id] = row[field.id];
                });
            };
            for (var _i = 0, _a = Object.values(view.fields); _i < _a.length; _i++) {
                var field = _a[_i];
                var state_2 = _loop_2(field);
                if (typeof state_2 === "object")
                    return state_2;
            }
        };
        var this_1 = this;
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            var state_1 = _loop_1(row);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    return NormalizedCache;
}());
exports.NormalizedCache = NormalizedCache;
